import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Footer, Navbar } from "../components";


export default function ContactPage()  {

  
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

    const form = useRef();
    //console.log("Ln 7 ContactUs called.. ");
    const sendEmail = (e) => {
      e.preventDefault();
       
    };


  

   return (
    
      <>
         <Navbar />
          <div className="container bg-primary text-white my-3 py-3">
          <h1 className="text-center">Contact Us</h1>
          <hr />

          <div className="row my-3">
          <div className="col-4 mx-auto">
          <p class="fs-4">Contact Address</p>
          <p class="fs-5">Sujanbagan, P.O.- Chinsurah</p> 
          <p class="fs-5">Dist. - Hooghly, West Bengal, 712101 India</p>
          <p class="fs-4">Contact Number</p>
          <p class="fs-5">8240169329/ 9433510469</p>
          <p class="fs-5">Mon.- Sat. 10:00 AM - 7:00 PM</p>
          <p class="fs-4">Email US </p>
          <p class="fs-5">contact@bairagi-consultants.com</p>
         
          <hr />
          </div>

          <div className="col-4 mx-auto">
            <hr />
                    </div>
                    </div>
                    </div>
                    <Footer/>
      </>
    );
  };