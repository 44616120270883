import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Footer, Navbar } from "../components/index.js";

import CustomMap from "../components/CustomMap.jsx";
import {AdvancedMarker, APIProvider, Map} from '@vis.gl/react-google-maps';

import "../components/main.css";

export default function BusinessSupport()  {

  const position = {lat: 22.9012, lng: 88.3899};

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

    const form = useRef();
    //console.log("Ln 7 ContactUs called.. ");
    const sendEmail = (e) => {
      e.preventDefault();
       
    };

const handleSubject=async(evt, param)=>{

      setSubject(param);
      //console.log("Entered Subject= " + subject);
    
    }
    
    const isDataValid = () => {
        if( subject.length == 0 || 
        message.length == 0 || 
        name.length == 0 || 
        email.length == 0 )
       return false;
         else
       return true;
    }

var templateParams = {
  message : "Your order is confirmed",
  name: 'Hi ' + name,
  subject: "Support and Customer Care team will reach you soon.. ",
  to_mail: email
 };
 
const InitiateMail =()=>{

  //console.log('Called InitiateMail Ln 47 ...');
  if(! isDataValid())
  {
    alert('Please enter valid data..');
    return;
  }
    

   emailjs.send('service_5l4zgi1','sunder_template_i9kyzm1', templateParams, 'xkVfdOdrH64JDAp3E')
    .then(function(response) {
       //console.log('Email Send SUCCESS!', response.status, response.text);
      alert("Sent email successfully..");
      setSubject("");
      setName("");
      setEmail("");
      setMessage("");
    }, function(error) {
       //console.log('FAILED to send mail...', error);
       alert("Sent email failed wit error "+ error);
    });
     
    }

    const handleName=async(evt, param)=>{

      setName(param);
      //console.log("LN 64 Entered name= " + name);
    
    }
    const handleEmail=async(evt, param)=>{

      setEmail(param);
      //console.log("Ln 70 Entered email= " + email);
    
    }
    const handleMessage=async(evt, param)=>{

      setMessage(param);
      //console.log("Entered Subject= " + message);
    
    }

    return (
      <>
        <Navbar />
        <div className="container bg-primary text-white my-3 py-3">
          <h1 className="text-center">Business Support</h1>
          <p class="fs-4">Business Support 24x7</p>
          <p class="fs-5">9432169467/ 8240169329</p>

          <div className="row my-3">
          <div className="col-6 mx-auto">
          <hr />
          <div className="text-center">
             
             </div>
             <div className="text-center">
             
             </div>
             <div className="text-center">
             
             </div>

          <APIProvider apiKey={'AIzaSyBBgi3tD_pHZo87l0tj62TYZTp1i3IB9tI'}>
     
      <Map defaultCenter={position} defaultZoom={13} mapId="locationmap-439012">
        <AdvancedMarker position={position} />
      </Map>
    </APIProvider>
          </div>

          <div className="col-6 mx-auto">
          <p class="fs-4">Submit a Request</p>
          <p class="fs-4"></p>
          <form>
                <div className="my-3">
                  <label htmlFor="display-4">Subject</label>
                  <input
                    type="subject"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Enter Subject"
                    name="subject"  
                    value={subject} 
                    onChange={event=>handleSubject(event, event.target.value)}
                  />
                </div>
                <div className="my-3">
                  <label htmlFor="floatingInput">Name</label>
                  <input
                    type="name"
                    className="form-control"
                    id="name"
                    placeholder="name"
                    name="name"  
                    value={name} 
                    onChange={event=>handleName(event, event.target.value)}
                  />
                </div>
                <div className="my-3">
                  <label htmlFor="floatingInput">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="email"
                    name="email"  
                    value={email} 
                    onChange={event=>handleEmail(event, event.target.value)}
                  />
                </div>
                <div className="my-3">
                  <label htmlFor="floatingInput">Message</label>
                  <input
                    type="TextArea"
                    rows="5"
                    cols="30"
                    className="form-control"
                    id="message"
                    placeholder="message"
                    name="message"  
                    value={message} 
                    onChange={event=>handleMessage(event, event.target.value)}
                  />
                </div>
                <div className="text-center">
             
              </div>
              <button className="my-2 mx-auto btn btn-secondary" type="Send Mail" onClick={InitiateMail}>
                  Send Mail
                </button>
               </form>
          </div>
              
          </div>
          </div>
        <Footer />
      </>
    );
  };