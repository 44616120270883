import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import Image from "react-bootstrap/Image";

import logo from '../images/logo.png';
import {
    MDBContainer,
    MDBCollapse,
    MDBNavbar,
    MDBNavbarToggler,
    MDBIcon,
    MDBBtn,
  } from 'mdb-react-ui-kit';
import { Button } from 'bootstrap';


const Navbar = () => {
    const [showNavExternal3, setShowNavExternal3] = useState(false);

const { loading, loginWithRedirect, logout,isAuthenticated, user } = useAuth0();
const state = useSelector((state) => state.handleCart);
const[loggedUserName, setloggedUserName] = useState('');
const[flag, setFlag] = useState(false);


useEffect(() => {
   
    if (isAuthenticated) {
        localStorage.setItem("UserInfo", JSON.stringify({user}));
        setloggedUserName(user.name);
    }
    else{
       localStorage.removeItem("UserInfo");

    }
    
  }, [isAuthenticated]);
  
   return (
    <>
        <nav className="navbar navbar-expand-lg navbar-light bg-primary font-color-white py-3 sticky-top">
            <div className="container">
            <button 
                type="button" 
                data-bs-toggle="collapse" 
                className="navbar-toggler" 
                data-bs-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
               <i class="fas fa-bars text-white"></i>
                </button>
            <Image src={logo}  rounded /> 
                <NavLink className="navbar-brand fw-bold text-white fs-4 px-2" to="/"> Bairagi Consultants</NavLink>
           

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto my-2 text-center">
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/">Home </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/about">About Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/project">Solutions</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/contact">Contact Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/BusinessSupport">Business Support</NavLink>
                        </li>
                       
                    </ul>
                    <p className='text-white'>
                    <MDBIcon icon="envelope" className="me-3 text-white" />contact@bairgi-consultants.com
                    </p>
               
                     <div>
                     { isAuthenticated &&
              
                        <h4 className="display-12 text-right text-white">Welcome {loggedUserName}</h4>

                    }
                     </div>
                 </div>
            </div>
        </nav>
        </>
    )
}

export default Navbar